<template>
	<div class="testdrive">
		<!-- 导航栏 -->
		<van-nav-bar
		class="nav_bar_color"
		title="创建试乘试驾邀请函"
		left-text="返回"
		left-arrow
		@click-left="onClickLeft"
		fixed
		z-index="11" 
		:border="false"
		placeholder
		>
	</van-nav-bar>
		
		
		<!-- 轮播图 -->
		<div class="testdrive_swiper">
			<van-swipe @change="change" class="swiper swiper_outside" circular :indicator-dots="indicatorDots" :interval="interval"
				:duration="duration" indicator-color="#757575" indicator-active-color="#ffffff">
				<van-swipe-item v-for="v,index in imgslist" :key="index">
					<img style="width: 100%;" :src="serverUrl(v.car_image)" mode="">
				</van-swipe-item>
			</van-swipe>
		</div>
		
		<div class="testdrive_title">
			客户信息
		</div>
		
		<input placeholder-class="placeholderinput" class="testdrive_inputs" type="text" v-model="form.name" placeholder="点击输入客户姓名（称呼）">
		<input placeholder-class="placeholderinput" class="testdrive_inputs" type="number" v-model="form.mobile" placeholder="点击输入客户电话">
		
		<div class="sex">
			<div @click="issex" :class="{'active':form.gender == 1}">
				男
			</div>
			<div @click="issex" :class="{'active':form.gender == 0}">
				女
			</div>
		</div>
		
		<div class="testdrive_title">
			客户分类
		</div>
		<div class="testdrive_classify">
			<div @click="invitation(0)" :class="{testdrive_img1:true,isborder:form.group == 0}">
				<span>不确定</span>
			</div>
			<div :style="{'background':imgurl}" @click="invitation(1)" :class="{testdrive_img2:true,isborder:form.group == 1}">
			</div>
			<div @click="invitation(2)" :class="{testdrive_img3:true,isborder:form.group == 2}">
			</div>
			<div @click="invitation(3)" :class="{testdrive_img4:true,isborder:form.group == 3}">
			</div>
		</div>
		
		<div class="testdrive_title">
			预约时间
		</div>
		
		<div placeholder-class="placeholderinput" @click="handCarModelShow" class="testdrive_inputs">{{ timevalue==''?'点击选择预约时间':timevalue }}</div>
		<CarModel3 :show="showCarModel" v-on:edit="handCarModel"></CarModel3>
	
		<div v-if="licenselist.length > 0" class="testdrive_title">
			预约车辆
		</div>
		
		<div @click="upcolor(v,index)" :class="{'isred':form.license_id==v.id,'ishui':v.reservation_time != ''}" class="testdrive_license" v-for="v,index in licenselist" :key="index">
			{{v.name}}
			<span v-if="v.reservation_time != ''">{{v.reservation_time}}</span>
		</div>
		
		<div class="user_footer" style="height: 91px;" :style="{'top':(docmHeight - 91) + 'px'}">
			<div class="" @click="save">
				邀请函预览
			</div>
		</div>
	</div>
</template>

<script>
import CarModel3 from '@/components/carmodel3.vue'
	export default {
		components: {
			CarModel3
		},
		data() {
			return {
				showCarModel:false,
				imgurl:'http://hm-h5-1.oss-cn-hangzhou.aliyuncs.com/imm-2212-scsj/icon_che1.png',
				current:0,
				timevalue:'',
				iscolor:'',
				form:{
					model_id:'',
					name:'',
					mobile:'',
					gender:0,
					group:0,
					time:'',
					license_id:''
				},
				licenselist:[],
				scrollTop: 0,
				imgslist:[],
				background: ['color1', 'color2', 'color3'],
				indicatorDots: true,
				autoplay: true,
				interval: 2000,
				duration: 500
			};
		},
		mounted(){
			const foo = localStorage.getItem('fromshijia')
			const timemodel = localStorage.getItem('timemodel')
			if(foo){
				this.form = JSON.parse(foo)
				this.timevalue = JSON.parse(timemodel)
				localStorage.removeItem('fromshijia')
				localStorage.removeItem('timemodel')
			}
			this.getimgslist()
		},
		computed: {    
			docmHeight() {
				return this.$store.state.height;
			}
		},
		methods:{
			// 预览邀请函
			save(){
				if(this.form.name.length == 0 || this.form.name.length>6){
					return this.$toast.fail('姓名长度为1~6')
				}
				if(this.form.mobile.length <= 6|| this.form.name.length>11){
					return this.$toast.fail('请输入正确电话')
				}
				if(this.form.time == ''){
					return this.$toast.fail('请选择时间')
				}
				localStorage.setItem('fromshijia',JSON.stringify(this.form))
				localStorage.setItem('timemodel',JSON.stringify(this.timevalue))
				this.$router.push({ // 跳转到tabbar
					path: '/invitation/test-drive-preview?option='+ JSON.stringify(this.form)
				})
			},
			// 时间选择
			handCarModelShow(e) {
				this.showCarModel = true
				setTimeout(() => {
					this.showCarModel = false
				}, 1000)
			},
			handCarModel(e) {
				this.timevalue = e.slice(5)
				var ti = e.replace('年','-')
				ti = ti.replace('月','-')
				ti = ti.replace('日','')
				var time = new Date(ti);
				this.form.time = time.getTime() / 1000;
				this.getlicense()
			},
			
			// 选择车牌
			upcolor(v,index){
				if(v.reservation_time != ''){
					return
				}
				this.form.license_id = v.id
			},
			
			// 获取车牌
			async getlicense(){
				this.form.model_id = this.imgslist[this.current].id
				const {data:res} = await this.$http.post('/api/scsj/get_car_reservation',{model_id:this.form.model_id,time:this.form.time})
				if(res.code == 1){
					this.licenselist = res.data.res
				}
			},
			// 客户分类
			invitation(e){
				this.form.group = e
			},
			// 性别选择
			issex(){
				this.form.gender == 0?this.form.gender = 1:this.form.gender = 0
			},
			// 轮播图切换
			change(e){
				this.current = e
				if(this.form.time){
					this.getlicense()
				}
			},
			 async getimgslist(){
				const {data:res} = await this.$http.post('/api/scsj/get_car_model_image_1_4')
				if(res.code == 1){
					this.imgslist = res.data.res
				}
			 },
		}
	}
</script>

<style lang="scss" scoped>
.testdrive{
	padding: 0 0 200px;
}
.testdrive_swiper{
	padding: 0 40px;
	.swiper_outside{
		height: 450px;
		span{
			font-family: "HYQiHeiY3-45";
		}
	}
}

.testdrive_title{
	margin: 32px 40px;
	font-size: 40px;
	font-weight: normal;
	font-family:HYQiHeiY3-45;
	color: #FFFFFF;
}

.testdrive_inputs{
	margin: 0 40px 24px;
	padding: 0 30px;
	width: 670px;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	border: none;
	font-size: 32px;
	font-weight: normal;
	color: #ffffff;
	font-family: HYQiHeiY3-45 !important;
	height: 88px;
	background: #2D2D2D;
	border-radius: 0px 0px 0px 0px;
}
.sex{
	margin: 0 40px;
	padding: 0 0 20px;
	display: flex;
	div{
		width: 126px;
		height: 80px;
		display: flex;
		justify-content: center;
		align-items: center;
		border: 1px solid #979797;
		font-size: 36px;
		font-family: HYQiHeiY3-45;
		font-weight: normal;
		color: #878787;
	}
}

.testdrive_classify{
	padding: 0 40px 20px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	div{
		width: 152px;
		height: 152px;
		border: 1px solid #191919;
	}
	.testdrive_img1{
		background: #2D2D2D;
		display: flex;
		justify-content: center;
		align-items: center;
		
		span{
			font-size: 28px;
			font-family: HYQiHeiY3-45;
			font-weight: normal;
			color: #FFFFFF;
		}
	}
	.testdrive_img2{
		background: url('http://hm-h5-1.oss-cn-hangzhou.aliyuncs.com/imm-2212-scsj/icon_che1.png');
		background-size:100% 100%;
	}
	.testdrive_img3{
		background: url('../../assets/drivroute/icon_che2.png');
		background-size:100% 100%;
	}
	.testdrive_img4{
		background: url('../../assets/drivroute/icon_che3.png');
		background-size:100% 100%;
	}
}

.active{
	background: #D8D8D8 !important;
	color: #191919 !important;
}
.isborder{
	border: 1px solid #FA0037 !important;
}

.user_footer{
	z-index: 2;
	position: fixed;
	// bottom: 0;
	// top: calc(100% - 182px);
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	background: #000000;
	text-align: center;
	div{
		width: 670px;
		height: 92px;
		line-height: 92px;
		background: #FA0037;
		text-align: center;
		font-size: 36px;
		font-family: HYQiHeiY3-55;
		font-weight: normal;
		color: #FFFFFF;
	}
}


.testdrive_license{
	margin: 0 32px 32px;
	height: 92px;
	font-size: 40px;
	font-family: HYQiHeiY3-55;
	font-weight: normal;
	color: #FFFFFF;
	text-align: center;
	border: 1px solid #979797;
	display: flex;
	justify-content: center;
	justify-content: center;
	flex-direction: column;
	span{
		font-size: 28px;
		font-family: HYQiHeiY3-45;
		font-weight: normal;
		color: #FFFFFF;
		line-height: 35px;
	}
}

.isred{
	background: #FA0037;
}

.ishui{
	background: #6F6F6F;
}
</style>
