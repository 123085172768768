<template>
	<div class="carmodel2">
		<!-- 车型选择弹窗 -->
		<van-popup v-model="carModelPopup"  position="bottom" :style="{'background-color':'#575757'}">
			<div class="carmodel__btns">
				<div @click="handCarModelClose">取消</div>
				<div @click="handCarModelOk">确定</div>
			</div>
			<div class="carmodel__list">
				<van-picker
					item-height="40"
					value-key="name"
					@change="handCarModelEdit"
					class="picker-div"
					:columns="timelist"
				>
				</van-picker>
			</div>
		</van-popup>
	</div>
</template>

<script>

export default {
	name: 'CarModel2',
	props: {
		show: Boolean
	},
	data() {
		return {
			systemTime:'',
			carmodelList: [],
			modelId: 0,
			collocationId: 0,
			index1:0,
			index2:0,
			modelList: [],
			collocationList: [],
			timelist:[],
			value:'',
			clienttime:'',
			carModelPopup:false,
		};
	},
	watch: {
		show(e) {
			if (this.show) {
				this.carModelPopup = true
			}
		},
	},
	mounted(){
		this.getCarModelList()
	},
	methods: {
		handCarModelEdit(picker,value,index2) {
			this.timelist.forEach((item,index) => {
				if(item.name == value[0]){
					this.index1 = index
					item.children.forEach((v,index2) => {
						if(v.name == value[1]){
							this.index2 = index2
						}
					})
				}
			})
			// this.index1 = e.detail.value[0]
			// this.index2 = e.detail.value[1]
		},
		handCarModelClose(e) {
			this.carModelPopup = false
		},
		handCarModelOk(e) {
			this.clienttime = this.modelList[this.index1]+' '+this.collocationList[this.index2]
			this.$emit('edit', this.clienttime)
			this.handCarModelClose()
		},
		// 获取时间
		getCarModelList(){
			this.collocationList = ['09:00','09:30','10:00','10:30','11:00','11:30','12:00','12:30','13:00','13:30','14:00','14:30','15:00','15:30','16:00','16:30','17:00','17:30','18:00','18:30','19:00','19:30','20:00','20:30',]
			const collocationLists = this.collocationList.map(item => {
				return {name:item}
			})
			
			var myDate = new Date();
			var beforeseven = new Date();
			var thirty = new Date();
			var sixty = new Date();
			var custom = new Date();
			let date = {
				year: myDate.getFullYear(),
				month: (myDate.getMonth() + 1)>9?(myDate.getMonth() + 1):'0'+(myDate.getMonth() + 1),
				date: myDate.getDate()
			}
			const systemTime = date.month + '月' + date.date + '日'
			//x.getDate() 返回月份的某一天，当前myDate.getDate()是返回当月的当天～ 比如今天是2021年09月17号，返回的是17
			//x.setDate() 设置月份的某一天，当前myDate.setDate(myDate.getDate())是设置为2021年09月17号
			myDate.setDate(myDate.getDate());
			beforeseven.setDate(beforeseven.getDate()-1 - 6);
			thirty.setDate(thirty.getDate()-1 - 29);
			for (var i = 0; i < 60; i++) {
				sixty = new Date();
				sixty.setDate(sixty.getDate() + i)
				this.modelList.push(sixty.getFullYear() +
					'年' + this.Twodigits(sixty.getMonth() + 1) + '月' + this.Twodigits(sixty.getDate()) + '日')
				if((this.Twodigits(sixty.getMonth() + 1) + '月' + this.Twodigits(sixty.getDate()) + '日') == systemTime){
					this.timelist.push({name:'今日',children:collocationLists})
				}else{
					this.timelist.push({name:this.Twodigits(sixty.getMonth() + 1) + '月' + this.Twodigits(sixty.getDate()) + '日',children:collocationLists})
				}
				
				}
		},
		Twodigits(num) {
		  return num < 10 ? '0' + num : num;
		}

	}
}
</script>

<style lang="scss">
.ddds{
	color: #000000;
}
.carlist{
	margin: 40px auto 0;
	width: 750px;
	.car{
		margin-left: 15px;
		margin-right: 15px;
		border: 1px solid rgba(255, 255, 255, 0.3);
		text-align: center;
		padding: 28px 0;
		&__name{
			overflow: hidden;
			margin-bottom: 10px;
			font-size: 36px;
			font-family: HYQiHeiY3-55;
			color: #fff;
			line-height: 40px;
		}
		&__model{
			font-size: 28px;
			font-family: HYQiHeiY3-45;
			color: #ECECEC;
			line-height: 32px;
		}
		&__add{
			font-size: 36px;
			font-family: HYQiHeiY3-55;
			color: #fff;
			line-height: 82px;
		}
	}
	.car--active{
		background: #FA0037;
		border: 1px solid #FA0037;
	}
	&__empty{
		padding-bottom: 50px;
		text-align: center;
		image{
			width: 88px;
			height: 54px;
		}
		&__init{
			margin-top: 26px;
			font-size: 32px;
			font-family: HYQiHeiY3-45;
			color: #575757;
			line-height: 34px;
		}
		&__add{
			margin: 98px auto 0;
			width: 260px;
			height: 72px;
			border: 1px solid rgba(151, 151, 151, 0.3);
			font-size: 32px;
			font-family: HYQiHeiY3-45;
			color: #ECECEC;
			line-height: 72px;
		}
	}
}
.carddd{
	background: #E8E8E8;
	width: 670px;
	border-radius: 20px;
	&__title{
		padding-top: 60px;
		text-align: center;
		font-size: 40px;
		font-family: HYQiHeiY3-65;
		color: #101010;
		line-height: 40px;
	}
	&__opts{
		margin-top: 60px;
		&__select{
			margin: 0 auto;
			width: 590px;
			height: 92px;
			line-height: 92px;
			border: 1px solid rgba(87 ,87 , 87, 0.3);
			text-align: center;
			position: relative;
			font-size: 32px;
			font-family: HYQiHeiY3-55;
			color: #333;
			image{
				position: absolute;
				right: 30px;
				top: 30px;
				width: 32px;
				height: 32px;
			}
		}
		&__input{
			margin: 20px auto 0;
			width: 590px;
			height: 92px;
			border: 1px solid rgba(87 ,87 , 87, 0.3);
			input{
				width: 100%;
				height: 100%;
				text-align: center;
				font-size: 32px;
				font-family: HYQiHeiY3-55;
				color: #333;
			}
		}
	}
	&__btns{
		margin-top: 60px;
		border-top: 1px solid #D7D7D7;
		display: flex;
		div{
			text-align: center;
			width: 50%;
			font-size: 36px;
			font-family: HYQiHeiY3-55;
			color: #323232;
			line-height: 90px;
		}
		div:first-child{
			border-right: 1px solid #D7D7D7;
		}
		div:last-child{
			color: #2C28E8;
		}
	}
}
.carmodel__btns{
	padding: 46px 40px 0;
	display: flex;
	justify-content: space-between;
	div{
		font-size: 36px;
		font-family: HYQiHeiY3-55;
		color: #B9B9B9;
		line-height: 36px;
	}
	div:last-child{
		color: #F3C846;
	}
}
.carmodel__list{
	width: 100%;
	box-sizing: border-box;
	padding: 0 54px 200px;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	.carmodel__item{
		margin-top: 30px;
		width: 300px;
		padding: 40px 24px 16px;
		text-align: center;
		&__logo{
			image{
				width: 100%;
			}
		}
		&__name{
			margin-top: 12px;
			font-size: 30px;
			font-family: Regular;
			color: #ECECEC;
			line-height: 30px;
		}
	}
	.carmodel__item--active{
		background: rgba(0, 0, 0, 0.1);
		border-radius: 14px;
	}
}
.picker-div {
	width: 100%;
	height: 300px;
	background-color: #575757;

	.van-picker__mask{
		background-image:none !important;
	}
	.van-ellipsis{
			color: #ffffff !important;
		}
}
.carmodel__list__item {
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	font-size: 40px;
	font-family: HYQiHeiY3-65;
	color: #fff;
}

.iscolor{
	color: #000000;
}
</style>